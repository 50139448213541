<template>
  <!-- 模版类型1 -->
  <template v-if="componentInfo && componentInfo.footerStyle === 1">
    <div class="footer-wrapper">
      <div class="footer-style1">
        <div class="copyright">
          <span class="mgr10">Copyright ©</span>{{ componentInfo.copyright }}
        </div>
        <div class="blogroll">
          <template v-for="item in componentInfo.customList">
            <span
              v-if="item.titleShow"
              :key="item.id"
              class="link_wrap"
            >
              <span
                v-if="!getLinkUrl(item.linkUrl)"
                class="link mgr10"
              >{{
                  item.title
                }}</span>
              <a
                v-else
                :href="item.linkUrl"
                class="link mgr10"
                target="_blank"
              >
                {{ item.title }}
              </a>
            </span>
          </template>
        </div>
      </div>
    </div>
  </template>
  <!-- 模版类型2 -->
  <template v-if="componentInfo && componentInfo.footerStyle === 2">
    <div class="footer-wrapper">
      <div class="footer-style2">
        <div class="w" style="display: flex">
          <div class="logo">
            <img src="@/assets/images/new/logodi@2x.png" style="width: 89px;height: 78px"/>
          </div>
          <div>
            <ul
              v-if="linkList.length"
              class="footer_nav"
            >
              <span>友情链接：</span>
              <li
                v-for="(item, index) in linkList"
                :key="index"
                class="nav_item"
              >
                <custom-link
                  :to="handleNavigate(item)"
                  :target-type="item.targetType"
                  :target-open-new-page="item.targetOpenNewPage"
                  custom-class="nav_item"
                >
                  <div class="nav_name">
                    {{ item.navName }}
                  </div>
                  <div
                    v-if="index !== linkList.length - 1"
                    class="border"
                  />
                </custom-link>
              </li>
            </ul>
            <div class="copyright">
              <span class="mgr10">Copyright ©</span>{{ componentInfo.copyright }}
            </div>
            <div class="blogroll">
              <template v-for="item in componentInfo.customList">
            <span
              v-if="item.titleShow"
              :key="item.id"
              class="link_wrap"
            >
              <span
                v-if="!getLinkUrl(item.linkUrl)"
                class="link mgr10"
              >{{
                  item.title
                }}</span>
              <a
                v-else
                :href="item.linkUrl"
                class="link mgr10"
                target="_blank"
              >
                {{ item.title }}
              </a>
            </span>
              </template>
            </div>
          </div>

          <div class="pop-view">
            <client-only>
              <!-- <el-popover
                placement="top"
                trigger="hover"
                :width="170"
              >
                <template #reference>
                  <div class="pop-img">
                    <img
                      class="active"
                      src="@/assets/images/new/app1@2x.png"
                      alt=""
                    >
                    <img
                      class="pointer"
                      src="@/assets/images/new/app@2x.png"
                      alt=""
                    >
                    <span>APP下载</span>
                  </div>

                </template>
                <template #default>
                  <div class="app_download_box">
                    <div class="code">
                      <qrcode
                        value="https://www.hbsydwpx.com/admin/APP/canglanAPP.apk"
                        :size="140"
                      />
                      <div class="code-text" style="margin-left: 20px;margin-top: 10px">
                        Android APP下载
                      </div>
                    </div>
                  </div>
                </template>
              </el-popover> -->
            </client-only>

            <client-only>
              <el-popover
                placement="top"
                trigger="hover"
                :width="170"
              >
                <template #reference>
                  <div class="pop-img">
                    <img
                      class="active"
                      src="@/assets/images/new/wx1@2x.png"
                      alt=""
                    >
                    <img
                      class="pointer"
                      src="@/assets/images/new/wx@2x.png"
                      alt=""
                    >

                    <span>官方公众号</span>
                  </div>

                </template>
                <template #default>
                  <div class="app_download_box">
                    <div class="code">
                      <qrcode
                        value="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzk0MjYwMzYyOQ==&scene=124#wechat_redirect"
                        :size="140"
                      />
                      <div class="code-text" style="margin-left: 36px;margin-top: 10px">
                        官方公众号
                      </div>
                    </div>
                  </div>
                </template>
              </el-popover>
            </client-only>
          </div>
        </div>

      </div>
    </div>
  </template>
  <!-- 模版类型3 -->

  <template v-if="componentInfo && componentInfo.footerStyle === 3">
    <div class="footer-wrapper">
      <div class="footer-style3 w">
        <div class="footer-left">
          <div class="row">
            {{ componentInfo.contentOne }}
          </div>
          <div class="row">
            {{ componentInfo.contentTwo }}
          </div>
          <div class="row line" />
          <div class="row copyright">
            <span class="mgr10">Copyright ©</span>{{ componentInfo.copyright }}
          </div>
          <div class="row blogroll">
            <template v-for="item in componentInfo.customList">
              <span
                v-if="item.titleShow"
                :key="item.id"
                class="link_wrap"
              >
                <span
                  v-if="!getLinkUrl(item.linkUrl)"
                  class="link mgr10"
                >{{
                    item.title
                  }}</span>
                <a
                  v-else
                  :href="item.linkUrl"
                  class="link mgr10"
                  target="_blank"
                >
                  {{ item.title }}
                </a>
              </span>
            </template>
          </div>
        </div>
        <div class="footer-right">
          <client-only>
            <el-popover
              v-if="componentInfo.showWechat"
              placement="top"
              trigger="hover"
            >
              <template #reference>
                <img
                  class="pointer mgr20"
                  src="@/assets/images/wechat.png"
                  alt=""
                >
                <!-- <i class="font32 iconfont icon-weixin"></i> -->
              </template>
              <img
                class="popover_img"
                :src="componentInfo.wechatUrl"
                alt=""
              >
            </el-popover>
          </client-only>
          <client-only>
            <el-popover
              v-if="componentInfo.showMiniApp"
              placement="top"
              trigger="hover"
            >
              <template #reference>
                <img
                  class="pointer mgr20"
                  src="@/assets/images/miniApp.png"
                  alt=""
                >
                <!-- <i class="font32 mgl20 iconfont icon-xiaochengxu"></i> -->
              </template>
              <img
                class="popover_img"
                :src="componentInfo.miniAppUrl"
                alt=""
              >
            </el-popover>
          </client-only>
          <a
            v-if="componentInfo.showWeibo"
            target="_blank"
            :href="componentInfo.weiboUrl"
          >
            <img
              class="pointer mgr20"
              src="@/assets/images/weibo.png"
              alt=""
            >
          </a>

          <!-- <i
            v-if="componentInfo.showWeibo"
            class="font32 mgl20 iconfont icon-weibo"
          ></i> -->
          <a
            v-if="componentInfo.showQq"
            target="_blank"
            :href="'https://wpa.qq.com/msgrd?y=3&uin='+ componentInfo.qqUrl + '&site=qq&menu=yes'"
          >
            <img
              class="pointer"
              src="@/assets/images/QQ.png"
              alt=""
            >
          </a>
          <!-- <i
            v-if="componentInfo.showQq"
            class="font32 mgl20 iconfont icon-QQ"
          ></i> -->
        </div>
      </div>
    </div>
  </template>
</template>
<script setup>
import { getFooter } from '@/api/layouts'
import { validateURL } from '@/utils/validate'
import { useLayoutStore } from '@/stores/layout'
const { setFooterType } = useLayoutStore()

const linkList = ref([
  {
    navName:'河北省人力资源和社会保障厅',
    targetType:90,
    targetAddr:'https://rst.hebei.gov.cn/index.html',
    targetOpenNewPage:true,
  },
  {
    navName: '河北省人民政府',
    targetType:90,
    targetAddr:'https://www.hebei.gov.cn/',
    targetOpenNewPage:true,
  },
  // {
  //   navName: '河北省事业单位工作人员培训管理平台',
  //   targetType:90,
  //   targetAddr:'https://www.hbsydwpx.com/admin/',
  //   targetOpenNewPage:true,
  // }
])

const getLinkUrl = (url) => {
  return validateURL(url) ? url : ''
}
const { data: componentInfo } = await useAsyncData('footer', async (ctx) => {
  return getFooter()
})
onMounted(() => {
  if (componentInfo.value) {
    setFooterType(componentInfo.value.footerStyle)
  }
})
</script>
<style lang="scss">
.footer-wrapper {
  width: 100%;
  background: #16223d;
  color: #becadb;
  line-height: 32px;
  .footer-style1 {
    vertical-align: center;
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-style2 {
    vertical-align: center;
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo{
      margin-right: 43px;
    }
    .pop-view{
      cursor: pointer;
      display: flex;
      margin-left: auto;
      align-items: center;
      .pop-img:last-child{
        margin-right: 0;
      }
    }
    .app_download_box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .code {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:first-child {
          margin-right: 30px;
        }

        .code-text {
          width: 100%;
          display: flex;
          justify-content: center;
          text-align: center;
          margin-top: 6px;
          margin-left: 5px;
        }
      }
    }
    .pop-img{
      margin: 0 25px;
      .active{
        display: none;
      }
      span{
        font-size:12px;
        color:#BCBBBB;
      }
      img{
        width: 55px;
        height: 55px;
      }
      .pointer{
        display: block;
      }
      &:hover{
        .active{
          display: block;
        }
        .pointer{
          display: none;
        }
      }
    }
    .footer_nav {
      display: flex;
      align-items: center;
    }
    .nav_item {
      display: flex;
      align-items: center;
      .nav_name {
        color: #becadb;
        white-space: nowrap;
        &:hover{
          color: white;
        }
      }
      .border {
        border: none;
        background: #becadb;
        display: inline-block;
        height: 10px;
        margin: 0 10px;
        width: 1px;
      }
    }
  }
  .footer-style3 {
    display: flex;
    justify-content: space-between;
    height: 260px;
    .footer-left {
      padding-top: 30px;
      height: 100%;
      .line {
        margin: 30px 0;
        width: 20px;
        height: 3px;
        background: #becadb;
      }
    }
    .footer-right {
      display: flex;
      align-items: center;
      height: 100%;
      .popover_img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
    }
  }
}
.link {
  color: #becadb;
}
</style>
